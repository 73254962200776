<template>
<vx-card :title="title" v-if="renderComponent">
    <template slot="actions">
        <vs-input v-model="searchString" class="m-0" @keyup="handleFilter" placeholder="Search"></vs-input>
    </template>
    <vs-table stripe :data="registration" v-if="registration.length" :sst="true" @sort="handleSort">
        <template #thead>
            <vs-th sort-key="displayName">Name</vs-th>
            <vs-th sort-key="mobileNumber">Phone No</vs-th>
            <vs-th sort-key="passNumber">Pass Number</vs-th>
            <vs-th sort-key="productName">Pass Name</vs-th>
            <vs-th class="width1"></vs-th>
            <vs-th class="width1" sort-key="registrationPlate">Registration</vs-th>
            <vs-th class="" sort-key="transactionDate">Purchase Date</vs-th>
            <vs-th class="width1" sort-key="status">Status</vs-th>
            <vs-th class="width1"></vs-th>
        </template>
        <template :slot-scope="{data}">
            <vs-tr v-for="(tr, index) in registration" :key="index" :data="tr">
                <vs-td>{{tr.displayName}}</vs-td>
                <vs-td>{{tr.mobileNumber}}</vs-td>
                <vs-td>
                    {{tr.passNumber}}
                    <feather-icon v-if="tr.isNew" color="success" icon="DiscIcon" svg-classes="h-5 w-5 text-success" class="ml-2" />
                    <vs-commnet-icon :registrationId="tr.id"></vs-commnet-icon>
                </vs-td>
                <vs-td>{{tr.productName}}</vs-td>
                <vs-td>
                    <vs-avatar :color="tr.statusColor ? tr.statusColor : '#ccc'" size="large" icon-pack="feather" icon="icon-credit-card" />
                </vs-td>
                <vs-td>
                    {{tr.registrationPlate}}
                </vs-td>
                <vs-td>{{tr.transactionDate | formatLongDate}}</vs-td>
                <vs-td>
                    <vs-chip color="success">{{tr.status}}</vs-chip>
                </vs-td>
                <vs-td>
                    <vs-button @click="editSelected(tr)">View Details</vs-button>
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData>
    <checkRegistration v-if="openAddWindow" :selectedReg="selectedReg" :regType="title" :openAddWindow="openAddWindow" @loadVehicles="loadVehicles" @onClose="handleClose"></checkRegistration>
</vx-card>
</template>

<script>
import {
    carData
} from "../../store/api/car";
import checkRegistration from './checkRegistration';
import VsCommnetIcon from "../../components/vs-comment-icon/VsCommentIcon.vue";

export default {
    data() {
        return {
            selectedReg: undefined,
            openAddWindow: false,
            intervalId: null,
            registration: [],
            title: "Pending Season Passes",
            searchString: "",
            noData: {
                title: "No Records Found",
            },
            renderComponent: true,
        };
    },
    components: {
        checkRegistration,
        VsCommnetIcon
    },
    async created() {
        await this.loadVehicles();
    },
    methods: {
        async loadVehicles() {
            this.registration = await carData.SearchPendingCarRegistration(this.searchString);
        },
        handleFilter() {
            if (this.intervalId) {
                clearTimeout(this.intervalId)
            }
            this.intervalId = setTimeout(async () => {
                await this.loadVehicles();
            }, 800);
        },
        async handleClose() {
            if (this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
                await this.loadVehicles();
                this.renderComponent = false;
                this.$nextTick(() => {
                    this.renderComponent = true;
                });
            }
        },
        async editSelected(tr) {
            this.selectedReg = tr;
            if (tr.isNew) {
                await carData.markAsReadRegistration(tr.id);
            }

            if (!this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
            }
        },
        handleSort(key, active) {
            this.registration = _.orderBy(this.registration, key, active);
        },
    }
};
</script>
